import React from "react"
import { graphql } from "gatsby"
import { usePageSetup } from "@components/usePageSetup"
import { Layout } from "@components/Layout"
import { Seo } from "@components/Seo"
import { Hero } from "@components/Hero"
import { Arrow } from "@components/UI"
import { splitText } from "@utils"
import { Blog } from "@components/Blog"

const IdeasAndInsightsPage = ({ data }) => {
  const posts = data.allWpPost.edges
  const category = { slug: "all", name: "Topic" }
  const type = { slug: "all", name: "Type" }
  const { themeColor } = usePageSetup({ themeColor: "bg-white-dark" })

  return (
    <Layout>
      <Seo title="Ideas and insights" description="" />
      {/* HERO */}
      <section className={themeColor}>
        <Hero className="pb-28 pt-clamp-16-26">
          <Hero.Title>
            <h1
              data-aos="stagger"
              dangerouslySetInnerHTML={{
                __html: splitText({
                  "text-gray-darkest": "Sharing ideas with people who share our passion.",
                }),
              }}
            />
          </Hero.Title>
          <Hero.Message overide={true}>
            <div className="w-full grid-cols-10 lg:grid sm:gap-x-4">
              <div className="col-span-9">
                <div data-aos="fade-up">
                  <p>
                    Our love for what we do shows up in many ways, including the articles we write, the webinars we
                    host, and the ideas we share. Please take a look around—we hope you learn something new.
                  </p>
                </div>
              </div>
            </div>
          </Hero.Message>
        </Hero>
      </section>
      <main className="overflow-hidden bg-gray-darkest">
        <section className="secondary pt-clamp-15-25">
          <Blog posts={posts} category={category} type={type} />
        </section>
      </main>
      <Arrow colorTop="bg-gray-darkest" colorBottom="bg-gray-darkest" fillColor="text-gray-darkest" />
    </Layout>
  )
}

export default IdeasAndInsightsPage

export const ideasAndInsightsQuery = graphql`
  query ideasAndInsightsQuery {
    allWpPost(sort: { fields: date, order: DESC }, filter: { id: { ne: "cG9zdDozMTY2" } }) {
      edges {
        node {
          id
          title
          slug
          excerpt
          date(formatString: "MMMM DD, YYYY")
          categories {
            nodes {
              name
              slug
            }
          }
          article {
            theme
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: CONSTRAINED)
                }
              }
            }
          }
        }
      }
    }
  }
`
